<template>
  <!--begin::Footer-->
  <div id="kt_footer" class="footer">
    <!--begin::Container-->

    <div class="fs-4 my-5 page p-10 pt-0">
    </div>
   
    <!--end::Container-->

    <!--begin::Footer-->
    <div
      class="mb-2 pe-4 page d-flex text-end justify-content-between row"
      style=""
      id="kt_aside_footer"
    >
      <div style="color: #666; font-size: 10px; bottom: 9px; overflow: hidden">
        Hecho con
        <img
          class="ps-1"
          height="8"
          src="data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjIuODggMTA3LjM5Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2VkMWIyNDtmaWxsLXJ1bGU6ZXZlbm9kZDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPnJlZC1oZWFydDwvdGl0bGU+PHBhdGggY2xhc3M9ImNscy0xIiBkPSJNNjAuODMsMTcuMThjOC04LjM1LDEzLjYyLTE1LjU3LDI2LTE3QzExMC0yLjQ2LDEzMS4yNywyMS4yNiwxMTkuNTcsNDQuNjFjLTMuMzMsNi42NS0xMC4xMSwxNC41Ni0xNy42MSwyMi4zMi04LjIzLDguNTItMTcuMzQsMTYuODctMjMuNzIsMjMuMmwtMTcuNCwxNy4yNkw0Ni40Niw5My41NUMyOS4xNiw3Ni44OSwxLDU1LjkyLDAsMjkuOTQtLjYzLDExLjc0LDEzLjczLjA4LDMwLjI1LjI5YzE0Ljc2LjIsMjEsNy41NCwzMC41OCwxNi44OVoiLz48L3N2Zz4="
          style="vertical-align: baseline"
        />
        desde Aragón por
        <a href="https://www.maubic.es/"
          ><img
            class="ps-1"
            height="14"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGgAAAAZCAMAAADqvJv3AAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAADxQTFRFAAAA5eXlDQ0NVlZWc3Nzl5eXGhoafHx8hoaGZmZm3t7ep6ens7OzQ0NDKysrwMDA0NDQzMzMuLi42dnZ0hJOHAAAABR0Uk5TAP8e////jv////////////////9rc6mqAAADB0lEQVR4nKWV2bbkKghAiYJDnJL0///rBVGTWpW6/dA8nDIqbCY5YPbtW3YAKPxLMITXh648L83c061j22L/7BJgKPczd6jBAm+cTrLbMtn1toXc8BPEJvwN2janynKEa/Nx/iEDhMvUsFq2tfwGeUS6veSTKJcp+p3z8QNkFKSmzL1M0+c30L2tING71IL5G6isgLaJxFG7v4NwXQH4ANX0Cdq1BThdx1DhzFxmrN9Bvuso6Hi00xP0rGgHHXtvB8a723icJr9B+3Go7gD12y8gN2u9QFFu9gjUqum3SHv4G6QiVVkg+wIKEMLqR01dZ0RJgFqVnnIu6sdrRFLO/RW0xzgDMuKu3IwTJFmzHbtsDym/amS69QXCBYIYtasS9HZyEpqZoNqt0rBabhA9QGpvgMS3NEBpzRNtKe3xClqntBlftJ77iMENq1Jqy4IfpdYYFui6QWUb72O4c0Fvqu4v2945QpggsXnMgmyP6WN7wdiMG402QPGROq0Dn1XCEUZgCzr+PEiAfoKGuzC6Ygw/6vQ7kf6zfdcIcmtr7+cHuN3MTTdGr4LAYpyvU4sOw4C7zYSP9r3MPVTH8Jas9Z8I/XUMkubCRFz55RSihej96iAvGQFL10HRjC0RjL1QxvvxVGu4rsuX6UgZ/bXEwL+IeVGf0W+/QHVNEfui/r3lOSQaQT5Pt3e5r9iVr7co6Xunss6Lwk+QaQlLS2S9RYgYMlGllL0/UzY5JXMmC+5PCKmhbYm3m0k5I9XaUoIzn5Qcf9vcMCf6DXLNQqqQvOeYIjbxlf/kECGFjCeeUvYMfzC07LgveFRyfK3fap4fLSUv/egi435GJMGyIxUo3KAyQUSlGiAS0FmKyzyxeCbTDWImgwKKQskQ23N+PcRKh2KLGbPN8cRWc+CkZAzEfZtsi94RJoDTsrWICZFBNjMrELIWnNQolYyxSUB4iuNBpb+DvtI545jm+AFUzov8q6ryEKsW2XAHVim5KVD0npHa194JosXqQRQMXzUm/ttz+T8pKdfn93/dWBlHRAKI/wAAAABJRU5ErkJggg=="
            style="filter: brightness(80%)"
        /></a>
      </div>
    </div>
    <!--end::Footer-->
  </div>
  <!--end::Footer-->
</template>
